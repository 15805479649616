
let WxUtil = {};
export default WxUtil;

/**
 * 微信初始化
 * 
 */
WxUtil.init = async function () {
    fetch('https://open.glztj.com/openApi/weixin/GetJsSdkPackage?appid=wx373d820aec8db91c&url=' + encodeURIComponent(location.href.split('#')[0])).then(response => {
        response.json().then(data => {
            console.log(data, '获取签名参数');
            wx.config({ // 完成初始化
                debug: false,
                appId: data.data.appId,
                timestamp: data.data.timestamp,
                nonceStr: data.data.nonceStr,
                signature: data.data.signature,
                // appId: 'wx373d820aec8db91c',
                // timestamp: '1682331637',
                // nonceStr: '64C3096DDCB3CF4FC9FED5596A69F167',
                // signature: '0ad3efa1a9937c72eb8bbb2592b5d5fd75aeba0c',
                jsApiList: [
                    //所有要调用的 API 都要加到这个列表中
                    'checkJsApi',
                    'openLocation',
                    'closeWindow',
                ],
                openTagList: ['wx-open-launch-weapp']
            })
        }

        ).catch(error => console.log(error))
    })
        .catch(error => console.log(error)
        )


}

/**
 * 使用微信内置地图查看位置接口
 * @param {*} locationParam 
 */
WxUtil.lookLocation = function (locationParam) {

    wx.ready(function () {
        wx.openLocation({
            type: 'gcj02',
            latitude: parseFloat(locationParam.latitude), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(locationParam.longitude), // 经度，浮点数，范围为180 ~ -180。
            name: locationParam.name, // 位置名
            address: locationParam.address, // 地址详情说明
            scale: 14, // 地图缩放级别,整形值,范围从1~28。默认为最大
            infoUrl: locationParam.infoUrl, // 在查看位置界面底部显示的超链接,可点击跳转						
        });
    });

}



//获取位置接口
WxUtil.receiveLocation = function (options) {
    var opts = {
        type: 'wgs84'
    };
    opts = Object.assign(opts, options, {});
    return new Promise((resolve, reject) => {

        wx.ready(function () {
            wx.getLocation({
                type: opts.type,
                success: function (res) {
                    resolve(res);
                },
                complete: function (res) {
                    reject(res);
                }
            });
        });


    });

}



