/**
 * 尽量用稳定的库
 */
 import cloneDeep from 'lodash/cloneDeep'
 import { stringify } from 'qs'
 
 /**
  * 深度对比两个对象是否一致
  * from: https://github.com/epoberezkin/fast-deep-equal
  * @param  {Object} a 对象a
  * @param  {Object} b 对象b
  * @return {Boolean}   是否相同
  */
 
 /* eslint-disable */
 export function equal(a, b) {
   if (a === b) return true
 
   if (a && b && typeof a == 'object' && typeof b == 'object') {
     var arrA = Array.isArray(a),
       arrB = Array.isArray(b),
       i,
       length,
       key
 
     if (arrA && arrB) {
       length = a.length
       if (length != b.length) return false
       for (i = length; i-- !== 0; ) if (!equal(a[i], b[i])) return false
       return true
     }
 
     if (arrA != arrB) return false
 
     var dateA = a instanceof Date,
       dateB = b instanceof Date
     if (dateA != dateB) return false
     if (dateA && dateB) return a.getTime() == b.getTime()
 
     var regexpA = a instanceof RegExp,
       regexpB = b instanceof RegExp
     if (regexpA != regexpB) return false
     if (regexpA && regexpB) return a.toString() == b.toString()
 
     var keys = Object.keys(a)
     length = keys.length
 
     if (length !== Object.keys(b).length) return false
 
     for (i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false
 
     for (i = length; i-- !== 0; ) {
       key = keys[i]
       if (!equal(a[key], b[key])) return false
     }
 
     return true
   }
 
   return a !== a && b !== b
 }
 
 // 是否是base64图片
 export function validDataUrl(s) {
   var regex = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i
   return regex.test(s)
 }
 export function testPhone (s) {
   let reg = /1\d{10}/
   return reg.test(s)
 } 
 export const formatDateByDate = (date, fmt = 'yyyy-MM-dd HH:mm:ss') => {
   if (date instanceof Date) {
     let format = fmt
     let o = {
       'M+': date.getMonth() + 1, // 月份
       'd+': date.getDate(), // 日
       'H+': date.getHours(), // 小时
       'm+': date.getMinutes(), // 分
       's+': date.getSeconds(), // 秒
       'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
       S: date.getMilliseconds() // 毫秒
     }
     if (/(y+)/.test(format)) {
       format = format.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length))
     }
     Object.entries(o).forEach(([key, value]) => {
       if (new RegExp(`(${key})`).test(format)) {
         format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? value : `00${value}`.substr(`${value}`.length))
       }
     })
     return format
   } else {
     return date
   }
 }
 
 // 格式化时间戳
 export const formatDate = (stamp, fmt = 'yy-MM-dd HH:mm') => {
   if (!stamp) {
     return ''
   }
   const date = new Date(+stamp * 1000)
   const resTime = formatDateByDate(date, fmt)
   return resTime
 }
 export const isNull = value => {
   if (value === null || value === undefined) return true
   return false
 }
 export const setTitle = (title) => {
   document.title = title
 }
 const baseUrl = process.env.VUE_APP_FILE_BASEURL || 'https://img.glztj.com'
 export const MyPlugin = {
   install(Vue) {
     Vue.filter('formatDateByDate', formatDateByDate)
     Vue.filter('formatDate', formatDate)
     Vue.prototype.$formatDate = formatDate
     Vue.prototype.$formatDateByDate = formatDateByDate
     Vue.prototype.$deepClone = cloneDeep
     Vue.prototype.$setTitle = setTitle
     Vue.prototype.$testPhone = testPhone
     Vue.directive('clickOutSide', {
       bind(el, binding) {
         function handler(e) {
           if (el.contains(e.target)) {
             return
           }
           if (binding.expression) {
             binding.value(e)
           }
         }
 
         el._vueClickOutSide = handler
         document.addEventListener('click', handler)
       },
       unbind(el) {
         document.removeEventListener('click', el._vueClickOutSide)
         delete el._vueClickOutSide
       }
     })
     Vue.filter('filterImg', (url) => {
      //  console.log(url, 'url')
       if(!url) return
      if (url.indexOf('http')>-1) {
        return url
      } else {
        return baseUrl + url
      }
     })
   }
 }
 
 /**
  * @return {string} 返回一个唯一的key
  */
 export function incrementKey() {
   return (
     Date.now().toString(32) +
     Math.random()
       .toString(32)
       .substr(2)
   )
 }
 
 export function queryStringify(query) {
   return stringify(query)
 }

 export function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}
 

export function getQueryString(url) {
	// 通过 ? 分割获取后面的参数字符串
	let urlStr = url.split('?')[1]
	// 创建空对象存储参数
	let obj = {};
	// 再通过 & 将每一个参数单独分割出来
	let paramsArr = urlStr.split('&')
	for (let i = 0, len = paramsArr.length; i < len; i++) {
		// 再通过 = 将每一个参数分割为 key:value 的形式
		let arr = paramsArr[i].split('=')
		obj[arr[0]] = arr[1];
	}
	return obj

}

export function queryString(item) {
  var sValue = location.href.match(
    new RegExp("[?&]" + item + "=([^&]*)(&?)", "i")
  );
  return sValue ? sValue[1] : sValue;
}


  /*判断客户端*/
  export function judgeClient() {
    let client = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
      client = 'iOS';
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
      client = 'Android';
    } else {
      client = 'PC';
    }
    return client;
  }


  //对象数组去重
  export function uniqueFunc(arr, uniId){
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
  }

  // 姓名脱敏
  export function noPassByName(str){
    if(null != str && str != undefined){
        if(str.length==2){
            return str.substring(0,1)+'*' //截取name 字符串截取第一个字符，
        }else if(str.length==3){
            return str.substring(0,1)+"*"+str.substring(2,3)//截取第一个和第三个字符
        }else if(str.length>3){
            return str.substring(0,1)+"*"+'*'+str.substring(3,str.length)//截取第一个和大于第4个字符
        }
    } else {
        return "";
    }
}