<template>
    <div>
        <h1 style="line-height:24pt; widows:2; orphans:2;    margin-top: 7.8pt;
    margin-bottom: 7.8pt;"><span style="font-family:">橄榄医院用户授权协议</span></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; text-align:right; font-size:12.5pt"><span
                style="font-family:; -aw-import:spaces">&#xa0;</span><span
                style="font-family:; -aw-import:spaces">&#xa0; </span><span
                style="font-family:">版本号：00002</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; text-align:right; font-size:12.5pt"><span
                style="font-family:; -aw-import:spaces">&#xa0;</span><span
                style="font-family:">更新时间：2023年4月10日</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; text-align:left; widows:2; orphans:2">
            <span style="font-family:; font-size:12.5pt; -aw-import:ignore">&#xa0;</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; text-align:left; widows:2; orphans:2">
            <span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">尊敬的客户，“我们”是广州橄榄医院有限公司（下称“橄榄医院”），“我们的服务”是指我们通过我们平台的客户端</span><span
                style="font-family:; font-size:12.5pt;">（包括但不限于H5网页、小程序，下称“本平台”）</span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">向您提供的互联网平台信息技术服务。在您使用我们的服务前，请仔细阅读如下服务条款：</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt"><span>1、</span></span></span><span
                style="font-family:; font-size:12.5pt">在体检预约服务及</span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">开通个人体检报告查询功能</span><span
                style="font-family:; font-size:12.5pt">的过程中，</span><span
                style="font-family:; font-size:12.5pt;">根据【{{hospitalInfo.name || hospitalInfo.hospitalName}}】建档及建立医疗关系的要求，</span><span
                style="font-family:; font-size:12.5pt">需要您授权本平台收集您</span><span
                style="font-family:; font-size:12.5pt; font-weight:bold">个人的姓名、联系方式、身份证号码、出生日期、性别、婚姻状况</span><span
                style="font-family:; font-size:12.5pt">。其中身份证号码为体检机构确认体检人的必要信息；性别、婚姻状况可能在您具体的体检项目有所差异；出生日期为排除体检机构限制（部分机构会限制16岁以下人员进行体检）；您提供的联系方式用于接收体检预约的过程中相关服务确认短信。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt; background-color:#ffffff"><span>2、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">您可以在本平台在线购买相应的产品和/或体检预约服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，您需要授权微信支付或支付宝进行款项结算，微信支付或支付宝可能会收集</span><span
                style="font-family:; font-size:12.5pt; font-weight:bold; background-color:#ffffff">您的第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)用于付款验证，以及收集您的收货地址信息，包括收货人姓名、电话、所在地区、详细地址</span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">。如您选择不提供以上个人信息，将导致您无法使用相关的支付功能，但不影响您使用基本浏览、搜索功能。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt"><span>3、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">当您签署本协议时，即表明您同意为您提供体检服务的机构及医院向本平台提供您的体检报告,并同意免于追究体检机构及医院提供体检报告的一切责任。如您选择不提供或不同意我们采集、使用以上个人信息，将导致您无法使用体检预约服务或者体检报告查询服务。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt"><span>4、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">本平台与各体检机构及医院实现体检数据对接，体检的套餐项目信息、排期信息、预约信息及体检报告数据将通过加密方式实时与体检机构/医院完成信息核实与传递，如经体检机构/医院方的接口返回您已经完成了体检并出具了电子格式的体检报告，您即可通过我平台或所约机构的官方网站报告查询入口查阅您的体检报告。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt; background-color:#ffffff"><span>5、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">为了您体检报告信息的完整性，我们会将您查询后的体检报告（含原始数据，但不包括乙肝检查结果等国家法律法规不允许公开的数据）加密后进行存档，便于您本人随时查看；为了更好地为您提供服务，您的体检报告结果可能会提供给与我们合作的第三方医疗机构，但我们会自动去掉和您个人有关的隐私信息（如姓名、身份证号码、电话、地址等）。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'6'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt; background-color:#ffffff"><span>6、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">在信息安全方面，我们保证用户信息存储在具有专业安全资质的机房，您可以阅读《用户隐私政策》进一步以了解您在使用产品或服务时，我们收集和使用您的个人信息的目的、方式和范围，您对您的个人信息的权利，以及我们为保护信息安全所采取的安全保护措施。</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; widows:2; orphans:2; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'7'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-size:12.5pt; background-color:#ffffff"><span>7、</span></span></span><span
                style="font-family:; font-size:12.5pt; background-color:#ffffff">为了更好地服务，我们将不断完善我们的服务内容，同时也希望您对我们的服务做出监督。至此，再次感谢您选择本平台为您服务！</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:25pt; font-size:12.5pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
    </div>
</template>

<script>
export default {
    computed:{
        hospitalInfo(){
            return JSON.parse(localStorage.getItem('hospitalInfo'))
        }
    }
}
</script>

<style>

</style>