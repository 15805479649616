import axios from 'axios'
// import { getToken, removeToken, setToken, removeUserInfo } from '@/config'
import { Toast } from 'vant';
import store from '@/store'
import { isWechat } from '@/utils'
axios.defaults.timeout = 300000
console.log(process.env.VUE_APP_BASEURL)
// 接口拦截配置
const NOT_LOADING = 1
const NOT_INTERCETOR = 2
const fetch = axios.create({
  // baseURL: process.env.VUE_APP_BASEURL + '/api',
  baseURL: '/api',
  ourConfig: 0
})

const requestUse = (config) => {
  //数据埋点接口只在微信环境下且非演示环境发送
  if(!isWechat() && config.url.includes('statistics') && store.state.fromPage === 'group-demo'){
    return
  }
  const ourConfig = config.ourConfig
  config.headers.Authorization = `Bearer ${store.state.token}`
  if(store.state.openid){
    config.headers.openId = store.state.openid
  }
  if(store.state.hospitalId){
    config.headers.hospitalId = store.state.hospitalId
  }
  if(store.state.enterpriseCode){
    config.headers.enterpriseCode = store.state.enterpriseCode
  }
  // isLoading
  if (!(ourConfig & NOT_LOADING)) {
    Toast.loading({
      duration: 0,
      overlay: true,
      loadingType: 'spinner'
    })
  }
  return config
}

const responseSuccess = (response) => {
  const ourConfig = response.config.ourConfig
  const isStatistics = response.config.url.includes('statistics')
  const token = response.headers.authorization
  const code = response.data.code
  const res = response.data
  if (token) {
    // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
    setToken(token)
  }
  if (!(ourConfig & NOT_LOADING)) {
    Toast.clear()
  }
  //数据埋点接口清除错误提示
  if(isStatistics) {
    Toast.clear()
    return res.data
  }
  if (ourConfig & NOT_INTERCETOR) {
    return res
  }
  if (code === 200) {
    return res.data
  }
  if (code === 400) {
    if (typeof res.message === 'object') {
      const keys = Object.keys(res.message)
      Toast({message: res.message[keys[0]][0], duration: 1000})
      return Promise.reject(res.message[keys[0]][0])
    } else {
      Toast({message: res.message, duration: 1000})
    }
  }
  if (code === 401) {
    // Toast(res.message)
    // removeToken()
    store.commit('setToken', '')
    return Promise.reject(res)
  }
  if (code === 500) {
    Toast({message: res.message, duration: 4000})
    return Promise.reject(res)
  }
  return res.data
}

const responseErr = (error) => {
  console.log(error)
  Toast.clear()
  // if (error.response.status > 500) {
  //   return Promise.reject(error)
  // }
  if (error.response.status === 401) {
    // Toast(error.response.data)
    // removeToken()
    // removeUserInfo()
    // router.push({path: '/home'})
    return Promise.reject(error)
  }
  try {
    Toast('服务器异常，请稍候再试')
  } catch (e) {
    console.log(e)
  }
  console.log('请求失败', error.response.status)
  
  return Promise.reject(error)
}

fetch.interceptors.request.use(requestUse)
fetch.interceptors.response.use(responseSuccess, responseErr)

export const axiosGet = (url, params, instance = axios, config) =>
  instance.get(url, { params, ...config })

export const axiosPost = (url, params, instance = axios, config) =>
  instance.post(url, params, config)

export const fetchGet = (url, params, type, type2) => axiosGet(url, params, fetch, {
  ourConfig: type | type2
})
export const fetchPost = (url, params, type, type2) => axiosPost(url, params, fetch, {
  ourConfig: type | type2
})
