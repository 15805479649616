<template>
  <div class="privacy" v-if="showPrivacy">
    <div class="content">
      <div class="des">
        在使用当前网页服务之前，请仔细阅读
        <span class="link" @click="showRule('privacy')">
          《用户隐私政策》
        </span>
        和
        <span class="link" @click="showRule('third')">
          《第三方数据信息共享清单》
        </span>
      </div>
      <div class="btns">
        <div class="item reject" @click="exitMiniProgram">拒绝</div>
        <div class="item agree" @click="handleAgreePrivacyAuthorization">
          同意
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPrivacy: {
      type: Boolean,
    },
  },
  data() {
    return {
      privacyContractName: "《用户隐私政策》",
    };
  },
  mounted() {},
  methods: {
    // 拒绝隐私协议
    exitMiniProgram() {
      // 直接退出
      wx.closeWindow();
    },
    // 同意隐私协议
    handleAgreePrivacyAuthorization() {
      this.$emit("closePrivacy");
    },
    showRule(type) {
      this.$emit("showRule", type);
    },
  },
};
</script>

<style scoped>
.privacy {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 15px 40px 15px;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content .title {
  text-align: center;
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.content .des {
  font-size: 13px;
  color: #666;
  text-align: justify;
  line-height: 1.6;
}

.content .des .link {
  color: #00c4b3;
}

.btns {
  margin-top: 18px;
  display: flex;
  justify-content: space-around;
}

.btns .item {
  justify-content: space-between;
  width: 122px;
  height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-sizing: border-box;
  border: none;
}

.btns .reject {
  background: #fff;
  color: #878D99;
  border: 1px solid #EDEFF0;
}

.btns .agree {
  background: #00c4b3;
  color: #fff;
}
</style>
