import Vue from "vue";
import Router from "vue-router";
import store from "@/store.js";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/wx",
      // redirect: '/home',
      component: () => import("@/views/wx"),
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home"),
    },
    {
      path: "/index/:id",
      name: "index",
      component: () => import("@/views/index"),
    },
    {
      path: "/good-detail/:id",
      name: "good-detail",
      component: () => import("@/views/goodDetail"),
    },
    {
      path: "/confirm-order/:id",
      name: "confirm-order",
      component: () => import("@/views/confirmOrder"),
    },
    {
      path: "/confirm-pay/:id",
      name: "confirm-pay",
      component: () => import("@/views/confirmPay"),
    },
    {
      path: "/appointment-detail/:id",
      name: "appointment-detail",
      component: () => import("@/views/appointmentDetail"),
    },
    {
      path: "/edit-appointment/:id",
      name: "edit-appointment",
      component: () => import("@/views/editAppointment"),
    },
    {
      path: "/person-center",
      name: "person-center",
      meta: {
        title: "个人中心",
      },
      component: () => import("@/views/personCenter"),
    },
    {
      path: "/appointment-list",
      name: "appointment-list",
      component: () => import("@/views/appointmentList"),
    },
    {
      path: "/report-list",
      name: "report-list",
      component: () => import("@/views/reportList"),
    },
    {
      path: "/order-list",
      name: "order-list",
      component: () => import("@/views/orderList"),
    },
    {
      path: "/order-detail/:id",
      name: "order-detail",
      component: () => import("@/views/orderDetail"),
    },
    {
      path: "/refund-detail/:id",
      name: "refund-detail",
      component: () => import("@/views/refund"),
    },
    {
      path: "/manual-detail/:id",
      name: "manual-detail",
      component: () => import("@/views/manual"),
    },
    {
      path: "/add-item/:id",
      name: "add-item",
      component: () => import("@/views/add-item/index"),
    },
    {
      path: "/invoice",
      name: "invoice",
      component: () => import("@/views/invoice"),
    },
    {
      path: "/group-poster",
      name: "group-poster",
      component: () => import("@/views/group/poster"),
    },
    {
      path: "/group-apply",
      name: "group-apply",
      component: () => import("@/views/group/apply"),
    },
    {
      path: "/group-apply-status",
      name: "group-apply-status",
      component: () => import("@/views/group/apply-status"),
    },
    {
      path: "/group-index",
      name: "group-index",
      component: () => import("@/views/group/index"),
    },
    {
      path: "/group-login",
      name: "group-login",
      component: () => import("@/views/group/login"),
    },
    {
      path: "/group-profile",
      name: "group-profile",
      component: () => import("@/views/group/profile"),
    },
    {
      path: "/group-demo",
      name: "group-demo",
      component: () => import("@/views/group/demo"),
    },
    {
      path: "/group-select",
      name: "group-select",
      component: () => import("@/views/group/select"),
    },
    {
      path: "/group-contact-list",
      name: "group-contact-list",
      component: () => import("@/views/group/contact-list"),
    },
    {
      path: "/group-contact-detail",
      name: "group-contact-detail",
      component: () => import("@/views/group/contact-detail"),
    },
    {
      path: "/questionnaire",
      name: "questionnaire",
      component: () => import("@/views/questionnaire"),
    },
    {
      path: "/questionnaire-result",
      name: "questionnaire-result",
      component: () => import("@/views/questionnaire-result"),
    },
    {
      path: "/questionnaire-recommend",
      name: "questionnaire-recommend",
      component: () => import("@/views/questionnaire-recommend"),
    },
    {
      path: "/questionnaire-business",
      name: "questionnaire-business",
      component: () => import("@/views/questionnaire-business"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log(to.query, "路由参数");
  const { recommendShareKey } = to.query;
  if (recommendShareKey) {
    sessionStorage.setItem("recommendShareKey", recommendShareKey);
  }
  store.commit("getOpenId", to);
  next();
});

export default router;
