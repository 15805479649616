import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import 'lib-flexible';
import vant from 'vant'
import 'vant/lib/index.css'
import './sass/index.scss'
import { MyPlugin } from '@/utils'
import VueClipboard from 'vue-clipboard2'
import WxUtil from "@/utils/wxUtils.js";
import {isWechat} from "@/utils/index.js";

if(isWechat()){
  WxUtil.init();
}

// import VConsole from 'vconsole'
console.log(process.env)

if (process.env.NODE_ENV === 'development') {
  // window.vConsole = new VConsole() // eslint-disable-line
}
Vue.config.productionTip = false
Vue.use(vant)
Vue.use(MyPlugin)
Vue.use(VueClipboard)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
