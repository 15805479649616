var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_vm._m(5),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("在体检预约服务及")]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("开通个人体检报告查询功能")]),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("的过程中，")]),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("根据【"+_vm._s(_vm.hospitalInfo.name || _vm.hospitalInfo.hospitalName)+"】建档及建立医疗关系的要求，")]),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("需要您授权本平台收集您")]),_c('span',{staticStyle:{"font-size":"12.5pt","font-weight":"bold"}},[_vm._v("个人的姓名、联系方式、身份证号码、出生日期、性别、婚姻状况")]),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("。其中身份证号码为体检机构确认体检人的必要信息；性别、婚姻状况可能在您具体的体检项目有所差异；出生日期为排除体检机构限制（部分机构会限制16岁以下人员进行体检）；您提供的联系方式用于接收体检预约的过程中相关服务确认短信。")])]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticStyle:{"line-height":"24pt","widows":"2","orphans":"2","margin-top":"7.8pt","margin-bottom":"7.8pt"}},[_c('span',{staticStyle:{}},[_vm._v("橄榄医院用户授权协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","text-align":"right","font-size":"12.5pt"}},[_c('span',{staticStyle:{"-aw-import":"spaces"}},[_vm._v(" ")]),_c('span',{staticStyle:{"-aw-import":"spaces"}},[_vm._v("  ")]),_c('span',{staticStyle:{}},[_vm._v("版本号：00002")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","text-align":"right","font-size":"12.5pt"}},[_c('span',{staticStyle:{"-aw-import":"spaces"}},[_vm._v(" ")]),_c('span',{staticStyle:{}},[_vm._v("更新时间：2023年4月10日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","text-align":"left","widows":"2","orphans":"2"}},[_c('span',{staticStyle:{"font-size":"12.5pt","-aw-import":"ignore"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","text-align":"left","widows":"2","orphans":"2"}},[_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("尊敬的客户，“我们”是广州橄榄医院有限公司（下称“橄榄医院”），“我们的服务”是指我们通过我们平台的客户端")]),_c('span',{staticStyle:{"font-size":"12.5pt"}},[_vm._v("（包括但不限于H5网页、小程序，下称“本平台”）")]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("向您提供的互联网平台信息技术服务。在您使用我们的服务前，请仔细阅读如下服务条款：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt"}},[_c('span',[_vm._v("1、")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_c('span',[_vm._v("2、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("您可以在本平台在线购买相应的产品和/或体检预约服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，您需要授权微信支付或支付宝进行款项结算，微信支付或支付宝可能会收集")]),_c('span',{staticStyle:{"font-size":"12.5pt","font-weight":"bold","background-color":"#ffffff"}},[_vm._v("您的第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)用于付款验证，以及收集您的收货地址信息，包括收货人姓名、电话、所在地区、详细地址")]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("。如您选择不提供以上个人信息，将导致您无法使用相关的支付功能，但不影响您使用基本浏览、搜索功能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt"}},[_c('span',[_vm._v("3、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("当您签署本协议时，即表明您同意为您提供体检服务的机构及医院向本平台提供您的体检报告,并同意免于追究体检机构及医院提供体检报告的一切责任。如您选择不提供或不同意我们采集、使用以上个人信息，将导致您无法使用体检预约服务或者体检报告查询服务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt"}},[_c('span',[_vm._v("4、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("本平台与各体检机构及医院实现体检数据对接，体检的套餐项目信息、排期信息、预约信息及体检报告数据将通过加密方式实时与体检机构/医院完成信息核实与传递，如经体检机构/医院方的接口返回您已经完成了体检并出具了电子格式的体检报告，您即可通过我平台或所约机构的官方网站报告查询入口查阅您的体检报告。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'5'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_c('span',[_vm._v("5、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("为了您体检报告信息的完整性，我们会将您查询后的体检报告（含原始数据，但不包括乙肝检查结果等国家法律法规不允许公开的数据）加密后进行存档，便于您本人随时查看；为了更好地为您提供服务，您的体检报告结果可能会提供给与我们合作的第三方医疗机构，但我们会自动去掉和您个人有关的隐私信息（如姓名、身份证号码、电话、地址等）。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'6'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_c('span',[_vm._v("6、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("在信息安全方面，我们保证用户信息存储在具有专业安全资质的机房，您可以阅读《用户隐私政策》进一步以了解您在使用产品或服务时，我们收集和使用您的个人信息的目的、方式和范围，您对您的个人信息的权利，以及我们为保护信息安全所采取的安全保护措施。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"NormalWeb",staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","widows":"2","orphans":"2","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'7'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_c('span',[_vm._v("7、")])])]),_c('span',{staticStyle:{"font-size":"12.5pt","background-color":"#ffffff"}},[_vm._v("为了更好地服务，我们将不断完善我们的服务内容，同时也希望您对我们的服务做出监督。至此，再次感谢您选择本平台为您服务！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"25pt","font-size":"12.5pt"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_vm._v(" ")])])
}]

export { render, staticRenderFns }