import * as api from './api'

export const GetProductPersonCategoryKeys = (params) => api.fetchGet('/Dict/GetProductPersonCategoryKeys', params, 1)

export const GetRefundReasons = (params) => api.fetchGet('/Dict/GetRefundReasons', params, 1)

export const GetProducts = (params) => api.fetchGet('/Hospital/GetProducts', params)

export const GetHospital = (params) => api.fetchGet('/Hospital/Get', params, 1)

export const GetProductItem = (params) => api.fetchGet('/Hospital/GetProductItem', params)

// 获取日历
export const GetSchedules = (params) => api.fetchGet('/Reserves/GetSchedules', params, 1)
// 获取日历
export const GetProductSchedules = (params) => api.fetchGet('/Reserves/GetProductSchedules', params, 1)

export const GetOrders = (params) => api.fetchGet('/Orders/GetOrders', params)

export const GetOrderItem = (params) => api.fetchGet('/Orders/GetOrderItem', params)

export const RefundOrder = (params) => api.fetchPost('/Orders/Refund', params)

export const CancelOrder = (params) => api.fetchPost('/Orders/Cancel', params)

export const SubmitOrder = (params) => api.fetchPost('/Orders/SubmitOrder', params)

export const ModifyOrderAppointment = (params) => api.fetchPost('/Orders/ModifyOrderAppointment', params)

export const GetToken = (params) => api.fetchGet('/Sms/GetToken', params, 1) 

export const SendSMS = (params) => api.fetchPost('/Sms/send', params) 

export const login = (params) => api.fetchPost('/Auth/login', params, 1) 

export const GetAppointmentOrders = (params) => api.fetchGet('/Orders/GetAppointmentOrders', params) 

export const GetOpenId = (params) => api.fetchGet('/Auth/GetMpOpenId', params) 

export const OrdersGetToken = (params) => api.fetchGet('/Orders/GetToken', params, 1) 

export const QueryStatus = (params) => api.fetchGet('/orders/QueryStatus', params, 1) 

export const Repay = (params) => api.fetchPost('/orders/Repay', params) 

export const GetReportList = (params) => api.fetchGet('/Orders/GetReportList', params)

export const GetOrderAppointmentDetl = (params) => api.fetchGet('/Orders/GetOrderAppointmentDetl', params, 1)

export const IssueAnInvoice = (params) => api.fetchPost('/Orders/IssueAnInvoice', params, 1) 

//获取多医院配置
export const GetGroupHospital = (params) => api.fetchGet('/Hospital/HospitalSubgroup', params, 1) 

//获取加项包数据
export const GetAddItemTree = (params) => api.fetchGet('/hospital/GetPackageAddtionalItemGroupList', params, 1) 

//校验加项包金额
export const CalcAddtionPackageAmount = (params) => api.fetchPost('/hospital/CalcPackageAddtionalPrice', params, 1) 

export const PostStatistics = (params) => api.fetchPost('/statistics', params, 1) 

export const GetJsSdkPackage = (params) => api.fetchGet('/GetJsSdkPackage', params, 1) 

//获取团检人均预算
export const getBudDictData = (params) => api.fetchGet('/BudgetDictData', params, 1) 

//提交团检申请
export const applyGroupInfo = (params) => api.fetchPost('/ApplyInfo', params) 

//获取机构导航

export const getEnterpriseNavigationInfo = (params) => api.fetchGet('/EnterpriseNavigationInfo', params) 

//验证是否有团检
export const verifyGroup = (params) => api.fetchPost('/Enterprise/Auth/VerifyGroup', params) 

//校验团检验证码
export const verifyGroupSms = (params) => api.fetchPost('/Enterprise/Auth/Verify', params) 

//获取团检信息
export const getBindingGroupInfo = (params) => api.fetchGet('/Enterprise/Auth/getBindingGroupInfo', params, 2)

//获取团检套餐
export const getGroupProducts = (params) => api.fetchGet('/Enterprise/Product/getProducts', params, 2) 

//获取对外招商套餐
export const getDemoProducts = (params) => api.fetchGet('/Enterprise/Product/enterpriseProducts', params, 2) 


//获取问卷问题
export const getQuestions = (params) => api.fetchGet('/Enterprise/Question/Questions', params, 2) 
//获取企业问卷问题
export const getQuestionsToBusiness = (params) => api.fetchGet('/Enterprise/Question/NormalQuestions', params, 2) 

//获取问卷结果
export const getAssessmentResults = (params) => api.fetchPost('/Enterprise/Question/AssessmentResults', params, 2) 
//获取企业问卷结果
export const getBusinessAssessmentResults = (params) => api.fetchPost('/Enterprise/Question/NormalAssessmentResults', params, 2) 

//获取免费加项

export const getFreeProjects = (params) => api.fetchGet('/Hospital/QueryFreeAdditionalPackage', params) 

//注销账号
export const logoff = (data) => api.fetchPost('/Auth/logoff', data) 

//是否需要图形验证码
export const needCheckValidate = (data) => api.fetchGet('/captcha/needCheckValidate', data, 1) 

//获取图形验证码
export const getCaptcha = (data) => api.fetchGet('/captcha/captcha', data, 1) 

//查询报告校验姓名
export const validateReportName = (params) => api.fetchPost('/orders/getReportName', params) 




