<template>
  <van-popup
    class="popup"
    v-model="showPopup"
    position="bottom"
    get-container="body"
    round
    safe-area-inset-bottom
    closeable
    @close="onClose"
  >
    <serviceRule v-if="type === 'service'"></serviceRule>
    <authRule v-if="type === 'auth'"></authRule>
    <privacyRule v-if="type === 'privacy'"></privacyRule>
    <third v-if="type === 'third'"></third>
  </van-popup>
</template>

<script>
import serviceRule from "@/components/service-rule.vue";
import authRule from "@/components/auth-rule.vue";
import privacyRule from "@/components/privacy-rule.vue";
import third from '@/components/third-rule.vue'
export default {
  components: {
    serviceRule,
    authRule,
    privacyRule,
    third
  },
  props: {
    type: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  watch: {
    show(val) {
      this.showPopup = val;
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup {
  padding: 0 10px;
}
</style>