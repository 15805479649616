<template>
  <div>
    <h2 class="title">第三方数据信息共享清单</h2>
    <p class="p">
      当你使用第三方提供的服务时，我们会在获得或确保第三方获得你的授权同意后，以及其他符合法律法规规定的情形下共享对应信息。你可以通过本清单中列举的相关信息了解第三方会如何处理你的个人信息。我们也会对第三方获取个人信息的行为进行严格约束，以保护你的个人信息安全。
    </p>
    <div class="list">
      <div class="list-item">第三方公司名称：深圳市腾讯计算机系统有限公司</div>
      <div class="list-item">产品/类型：微信</div>
      <div class="list-item">收集个人信息类型：设备标识信息</div>
      <div class="list-item">
        使用目的：用于微信登录，打开地图和移动应用端用户完成订单的结算
      </div>
      <div class="list-item">使用场景：用户登录；用户打开地图；用户支付</div>
      <div class="list-item">共享方式：SDK本机采集</div>
      <div class="list-item">
        第三方个人信息处理规则：<a
          class="link"
          href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy"
          >点击链接</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}
.p {
  margin-top: 20px;
  font-size: 14px;
}

.list {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 14px;
}

.list-item {
  line-height: 1.5;
  margin: 5px 0;
}

.list-item .link {
  color: #0000ee;
}
</style>
